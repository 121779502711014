<template>
  <h1 class="pl-3">Send me a message</h1>
  <ContactMeForm />
</template>

<script>
import ContactMeForm from "../components/ContactMeForm.vue";

export default {
  data() {
    return {
      email: "",
      name: "",
      tel: "",
      role: "",
      notice: false,
      message: ""
    };
  },
  components: { ContactMeForm },
};
</script>

<style></style>
