<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-xs-12 mt-3">
        <label class="form-label">Your Name:</label>
        <input
          class="form-control"
          type="text"
          required
          v-model="name"
          placeholder="what do I call you?"
        />
        <p class="error">Please enter your first and last name</p>
      </div>
      <div class="col-lg-3 col-md-6 col-xs-12 mt-3">
        <label class="form-label">Your Email:</label>
        <input
          class="form-control"
          type="email"
          required
          v-model="email"
          placeholder="name@something.com"
        />
        <p class="error" v-show="!email.valid">
          Please enter a valid email address.
        </p>
      </div>
      <div class="col-lg-3 col-md-6 col-xs-12 mt-3">
        <label class="form-label">Phone</label>
        <input
          class="form-control"
          type="tel"
          required
          v-model="tel"
          placeholder="(xxx) xxx-xxxx"
          @input="acceptNumber"
        />
        <p class="error" v-show="!tel.valid">
          Please enter a valid phone address.
        </p>
      </div>
      <div class="col-lg-3 col-md-6 col-xs-12 mt-3">
        <label class="form-label">Collector or Commission</label>
        <select class="form-select" v-model="role">
          <option value="" disabled selected>select one</option>
          <option value="collector">Collector</option>
          <option value="commission">Commission something</option>
        </select>
      </div>
      <div class="col-12 mt-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="notice"
            v-model="notice"
          />
          <label class="form-check-label" for="notice">
            Recieve notifications about Jay's events, updates and drops
          </label>
          <p class="text-info fineprint">
            I will not sell or disclose your info. I hate spam. Just as much as
            you , if not more and will only send out notices when I have worthy
            news.
          </p>
        </div>
      </div>

      <div class="col">
        <label class="form-label">Message</label>
        <textarea
          class="form-control"
          id="message"
          rows="3"
          v-model="message"
        ></textarea>
        <p class="error" v-show="!message.valid">
          What do you want to tell me?
        </p>
      </div>

      <button class="btn btn-primary mt-5">Send It</button>
    </div>
  </form>
  <code>
    <pre> Form Values: <br>
    name: {{ name }} 
    email: {{ email }} 
    tel: {{ tel }} 
    notice: {{ notice }} 
    role: {{ role }} 
    message: {{ message }} 
    </pre>
  </code>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      tel: "",
      role: "",
      notice: false,
      message: "",
      submitted: false,
    };
  },
  methods: {
    noticeCheck() {
      this.notice = !this.notice;
    },
  },
};
</script>

<style>
form {
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
.fineprint {
  line-height: 1em;
  font-size: 80%;
}
.error {
  color: red;
  margin-top: 1rem;
  line-height: 1em;
  font-size: 80%;
}
</style>
